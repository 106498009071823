import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { StreamHealthReport } from '../types/StreamHealthReport';
import { date_format_yyyymmdd_to_date } from '../utils/helpers';

// The integration exports only a default component that at the same time is a
// namespace for the related Props interface (HighchartsReact.Props) and
// RefObject interface (HighchartsReact.RefObject). All other interfaces
// like Options come from the Highcharts module itself.

const StreamHealthScatterplot = (props: {
  reportsToUse: StreamHealthReport[];
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const streamHealthReports =
    props.reportsToUse != null ? props.reportsToUse : [];

  const dataPoints =
    streamHealthReports.length != 0
      ? streamHealthReports.map((point) => {
          return [
            date_format_yyyymmdd_to_date(
              `${point.measurementDate}`
            )?.getTime() ?? 0,
            Number.parseFloat(point.measuredResult ?? '0'),
          ];
        })
      : [];

  dataPoints.map((point) => {
    if (typeof point[0] !== 'number' || typeof point[1] !== 'number')
      console.warn('Non number found');
  });
  console.log('Got data points:' + dataPoints);

  const measurementUnits = 'MILLIGRAM PER LITER';

  const labelXAxis = 'date';

  const options: Highcharts.Options = {
    title: {
      text: 'Observed Contaminant Values Over Time',
    },
    xAxis: {
      title: {
        text: labelXAxis,
      },
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: measurementUnits,
      },
    },
    series: [
      {
        type: 'scatter',
        data: dataPoints,
      },
    ],
  };

  return (
    <>
      {streamHealthReports.length != 0 ? (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
          />
        </div>
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default StreamHealthScatterplot;
