import { AirQualityReport } from '../types/AirQualityReport';
import { API_URL, GRAPHS_URL } from './serviceConstants'; // Import constants

export const getAllAirQualityReports = async (): Promise<
  AirQualityReport[] | string
> => {
  console.log(
    'Fetching all air quality reports from:',
    `${API_URL}${GRAPHS_URL}`
  );

  try {
    const response = await fetch(`${API_URL}${GRAPHS_URL}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('API response:', data);
    return data;
  } catch (error) {
    console.error(
      'Error fetching stream health reports in streamHealthService:',
      error
    );
    return `${error}`;
  }
};
