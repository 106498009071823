import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AirQualityReport } from '../types/AirQualityReport';
import { getAllAirQualityReports } from '../services/airQualityService';

const AirQualityGraph = (props: { reportsToUse?: AirQualityReport[] }) => {
  const [reports, setReports] = useState<AirQualityReport[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (props.reportsToUse && props.reportsToUse.length > 0) {
        setReports(props.reportsToUse);
      } else {
        try {
          const data = await getAllAirQualityReports();
          setReports(data as AirQualityReport[]);
        } catch (error) {
          console.error('Error fetching air quality reports:', error);
        }
      }
    };

    fetchData();
  }, [props.reportsToUse]);

  const combinedData = reports.reduce(
    (acc: { [key: string]: number }, report) => {
      const district = report.district || 'Unknown';
      if (acc[district]) {
        acc[district] += report.numberOfExceedances;
      } else {
        acc[district] = report.numberOfExceedances;
      }
      return acc;
    },
    {}
  );

  const districts = Object.keys(combinedData);
  const exceedances = Object.values(combinedData);

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Number of Exceedances per District',
    },
    xAxis: {
      categories: districts,
      title: {
        text: 'District',
      },
      labels: {
        allowOverlap: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Exceedances',
      },
      allowDecimals: false,
      labels: {
        allowOverlap: true,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><br/>',
      pointFormat: '<b>{point.y}</b> exceedances',
    },
    series: [
      {
        type: 'column',
        name: 'Exceedances',
        data: exceedances,
        color: '#7cb5ec',
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <h1>Air Quality Exceedances</h1>
      {reports.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <p>No data available to display the chart.</p>
      )}
    </div>
  );
};

export default AirQualityGraph;
