import { StreamHealthReport } from '../types/StreamHealthReport';

//const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/streamHealth' : '/api/streamHealth';
const API_URL = '/api';
console.log('API_URL:', API_URL); // Log the API URL

export const getAllStreamHealthReports = async (): Promise<
  StreamHealthReport[] | string
> => {
  const API_PATH = '/data/graph/streamContaminants';
  console.log(
    'Fetching all stream health reports from:',
    `${API_URL}${API_PATH}`
  ); // Log the full endpoint

  try {
    const response = await fetch(`${API_URL}${API_PATH}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('API response:', data); // Log the response data
    return data;
  } catch (error) {
    console.error(
      'Error fetching stream health reports in streamHealthService:',
      error
    );
    return `${error}`;
  }
};
