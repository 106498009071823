import React, { useEffect, useState } from 'react';
import './App.css';
import StreamHealthScatterplot from './components/StreamHealthScatterplot';
import { StreamHealthReport } from './types/StreamHealthReport';
import { getAllStreamHealthReports } from './services/streamHealthService';
import AirQualityGraph from './components/AirQualityGraph';
import { AirQualityReport } from './types/AirQualityReport';
import { getAllAirQualityReports } from './services/airQualityService';

const App: React.FC = () => {
  const [streamHealthReports, setStreamHealthReports] = useState<
    StreamHealthReport[]
  >([]);
  const [airQualityReports, setAirQualityReports] = useState<
    AirQualityReport[]
  >([]);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [airQualityHasLoaded, setAirQualityHasLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchStreamHealthReports = async () => {
      try {
        const retrievedReports = await getAllStreamHealthReports();
        if (Array.isArray(retrievedReports)) {
          setStreamHealthReports(retrievedReports as StreamHealthReport[]);
        } else {
          console.error('Failed to fetch notes');
        }
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'Unknown error';
        console.error('Failed to fetch notes ' + errorMessage);
      } finally {
        setHasLoaded(true);
      }
    };
    fetchStreamHealthReports();
  }, []);

  useEffect(() => {
    const fetchAirQualityReports = async () => {
      try {
        const retrievedReports = await getAllAirQualityReports();
        if (Array.isArray(retrievedReports)) {
          setAirQualityReports(retrievedReports as AirQualityReport[]);
        } else {
          console.error('Failed to fetch air quality reports');
        }
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'Unknown error';
        console.error('Failed to fetch air quality reports: ' + errorMessage);
      } finally {
        setAirQualityHasLoaded(true);
      }
    };
    fetchAirQualityReports();
  }, []);

  return (
    <div className="App">
      <div className="App-header">
        <h1>YAWA</h1>
        <h2>Product Pitch</h2>
        <div className="App-details-section">
          <p>
            Introducing YAWA: Your Go-To Solution for Tracking Industrial
            Pollution! In a world where pollution is an escalating concern, it
            {"'"}s crucial to comprehend how industrial activities impact our
            air and water. YAWA simplifies the visualization of the connections
            between industrial wastewater, air emissions, and stream water
            quality. Our platform helps identify instances where pollution
            exceeds safe levels and its real-time impact on water bodies.
            Whether you{"'"}re a researcher, policymaker, or environmental
            activist, YAWA will equip you with the insights needed to make
            informed decisions. Our intuitive system breaks down complex data
            into easily understandable, customizable graphs and a simple
            dashboard with some of the most important information at a glance.
            Never feel astray again - with YAWA{"'"}s data at your fingertips,
            you{"'"}ll never find yourself lost in the data again!
          </p>
        </div>
      </div>

      <div className="App-section">
        <h2>Stream Health: Alkalinity Measurements</h2>
        <div className="App-details-section">
          {hasLoaded ? (
            <StreamHealthScatterplot reportsToUse={streamHealthReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>

      <div className="App-section">
        <h2>Air Quality: Exceedances by District</h2>
        <div className="App-details-section">
          {airQualityHasLoaded ? (
            <AirQualityGraph reportsToUse={airQualityReports} />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
